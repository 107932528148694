










import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import moment from "moment"
import "moment-duration-format"

@Component
export default class Timer extends Vue {
  remainingTimeStr = ""
  remainingTime = 0
  timer = 0

  @(Prop({
    type: Boolean,
    default: false,
  })!)
  isMultipassOrder!: Boolean

  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  order: any

  mounted() {
    this.timer = setInterval(this.getRemainingTime, 200)
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }

  getRemainingTime() {
    if (!this.order.expiration) {
      return
    }

    this.remainingTime = moment(this.order.expiration).diff(moment())

    if (this.remainingTime > 0) {
      const milisecondsLeft = moment.duration(this.remainingTime).asMilliseconds()
      this.remainingTimeStr = moment.utc(milisecondsLeft).format("mm:ss")
    } else {
      this.remainingTimeStr = ""
    }
  }

  @Watch("remainingTime")
  remainingTimeChanged(value: number) {
    if (value <= 0) {
      if (this.isMultipassOrder) {
        this.$router.push({
          name: "multipass-order-expired",
          query: this.$defaultQuery(),
        })
      } else {
        this.$store.dispatch("order/resetEvent")
        this.$router.push({ name: "order-expired", query: this.$defaultQuery() })
      }
    }
  }
}
