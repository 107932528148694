<template>
  <div class="card">
    <div class="card-content">
      <translate tag="h1">CONDICIONES GENERALES DE USO</translate>
      <translate tag="p">
        1. Queda prohibida la reproducción, copia, modificación o distribución de los
        contenidos con finalidades publicitarias o comerciales, sin la autorización
        expresa de la Fundación Galicia Obra Social (“Afundación, en lo sucesivo”), con
        domicilio social en A Coruña, Cantón Grande 8, Código Postal 15003, N.I.F.
        G-70270293 e inscrita en el Rexistro Único de Fundacións de Interese Galego,
        Sección da Consellería de Facenda, de la Xunta de Galicia con el número 2013/12,
        a la que corresponde la titularidad del portal www.ataquilla.com. En caso
        contrario se estaría incurriendo en una infracción penalizada por la legislación
        vigente. También, el usuario responderá de cualquier acción que, directa o
        indirectamente, provoque un daño o perjuicio al portal. La totalidad de este
        portal: texto, imágenes, marcas, logos, archivos, componentes de audio y video,
        está protegido por la Ley de la Propiedad Intelectual.
      </translate>
      <translate tag="p">
        2. El usuario se obliga al uso correcto, lícito y diligente del contenido y
        servicios que se ofrecen y se compromete a no utilizarlos con finalidades
        contrarias a la ley o al orden público. El usuario no utilizará los servicios y
        contenidos de manera que pueda suponer un peligro para el portal provocando su
        inutilización, sobrecarga o deterioro.
      </translate>
      <translate tag="p">
        3. Afundación rechaza toda responsabilidad sobre cualquier información no
        contenida en estas páginas Web y que, por lo tanto, no ha elaborado o no ha
        publicado con su nombre. De modo especial, rechaza toda responsabilidad por los
        daños y perjuicios debidos al acceso, funcionamiento, disponibilidad o
        continuidad de los mismos, así como de la calidad del servicio, fiabilidad y
        legitimidad de los contenidos e información que se publica en páginas Web de
        terceras personas conectadas por enlaces (link) con las páginas Web del portal.
      </translate>
      <translate tag="p">
        4. Las condiciones comerciales de este servicio y las ofertas que eventualmente
        puedan llevarse a cabo por el portal siempre aparecen en la mencionada página
        Web por lo que pueden ser consultadas, archivadas o impresas. Afundación se
        reserva el derecho de modificar en cualquier momento las presentes Condiciones
        Generales, así como cualesquiera otras condiciones generales o particulares,
        reglamentos de uso o avisos que resulten de aplicación. Afundación podrá
        modificar las Condiciones Generales notificándolo con antelación suficiente, con
        el fin de mejorar los servicios y productos ofrecidos a través del mismo.
        Mediante la modificación de las Condiciones Generales expuestas en la página
        Web, se entenderá por cumplido dicho deber de notificación. En todo caso, antes
        de utilizar los servicios o contratar productos, se pondrán consultar las
        Condiciones Generales. Asimismo se reserva el derecho a modificar en cualquier
        momento la presentación, configuración y localización del Sitio Web, así como
        los contenidos y las condiciones requeridas para utilizar los mismos.
      </translate>
      <translate tag="p">
        5. Afundación no será responsable de los retrasos o fallos que se produjeran en
        el acceso, funcionamiento y operatividad de la Web, o en sus servicios y/o
        contenidos, así como tampoco de las interrupciones, suspensiones o el mal
        funcionamiento de la misma, cuando tuvieren su origen en averías producidas por
        catástrofes naturales o situaciones de fuerza mayor , o de urgencia extrema,
        tales como huelgas, ataques o intrusiones informáticas o cualquier otra
        situación de fuerza mayor o causa fortuita, así como por errores en las redes
        telemáticas de transferencia de datos. El usuario se compromete a cumplir con lo
        establecido en las Condiciones Generales publicadas por Afundación en la Web en
        cada momento. El usuario reconoce y acepta que el acceso y uso del sitio Web
        www.ataquilla.com y de los contenidos incluidos en el mismo tiene lugar de forma
        libre y conscientemente, bajo su exclusiva responsabilidad. El usuario se
        compromete a hacer un uso adecuado y lícito del Sitio Web y de los contenidos,
        de conformidad con la legislación aplicable, las presentes Condiciones
        Generales, la moral y buenas costumbres generalmente aceptadas y el orden
        público.
      </translate>
      <translate tag="p">
        6. Afundación rechaza toda responsabilidad derivada del mal uso de los
        contenidos de la Web y se reserva el derecho a actualizarlos cuando lo desee,
        eliminarlos y limitar o impedir el acceso a ellos, de forma temporal o
        definitiva.
      </translate>
      <translate tag="p">
        7. La información contenida en esta Web es la vigente en la fecha de su última
        actualización y debe ser considerada como información introductoria para el
        usuario relativa a los productos, servicios y otras informaciones contenidas en
        estas páginas.
      </translate>
      <translate tag="p">
        8. Afundación se reserva el derecho de suspensión o modificación de las
        condiciones de acceso a su Web, así como del cambio de contenidos y servicios,
        sin previo aviso, por razones técnicas u otros motivos.
      </translate>
      <translate tag="p">
        9. Afundación no se responsabiliza de los posibles errores de seguridad que
        pudieran producirse por la utilización de ordenadores infectados con virus
        informáticos ni de las consecuencias que pudiesen derivarse del mal
        funcionamiento del navegador o por el uso de versiones no actualizadas del
        mismo.
      </translate>
      <translate tag="p">
        10. Ambas partes declaran expresamente que la aceptación de la oferta de
        servicio del portal por el usuario se lleva a cabo a través del seguimiento del
        procedimiento de compra descrito en el apartado Información y Funcionamiento. El
        hecho de cumplimentar telemáticamente todos los pasos descritos para el proceso
        de compra de la/s entrada/s por el usuario supone la aceptación integra y
        expresa de las presentes condiciones generales.
      </translate>
      <translate tag="p">
        11. El usuario es el único responsable de la veracidad de los datos introducidos
        por él mismo en el procedimiento de compra, y acepta la obligación de facilitar
        datos veraces, exactos y completos. Si el usuario incumple esta obligación,
        quedará bajo su responsabilidad el responder por los posibles daños y perjuicios
        producidos al portal o a un tercero.
      </translate>
      <translate tag="p">
        12. El usuario debe asegurarse fehacientemente antes de tramitar la reserva de
        la exactitud y adecuación de los datos introducidos, ya que no es posible la
        devolución de las entradas adquiridas una vez realizada la compra. No poder
        asistir al espectáculo o cometer un error al adquirir las entradas no son
        motivos que permitan su devolución.
      </translate>
      <translate tag="p">
        13. La prestación del servicio de la Web www.ataquilla.com tiene una duración
        indefinida. No obstante, Afundación está autorizado para dar por terminada o
        suspender la prestación del servicio en cualquier momento, sin perjuicio de lo
        que se hubiere dispuesto al respecto en las correspondientes condiciones
        generales. Cuando ello sea razonablemente posible, el portal advertirá
        previamente la terminación o suspensión de la prestación del servicio.
      </translate>
      <translate tag="p">
        14. El usuario se somete, con renuncia expresa a cualquier otro fuero, a los
        juzgados y tribunales de la ciudad de Vigo (España ). Estas Condiciones
        Generales se rigen por la ley española. Ambas partes reconocen que la
        legislación aplicable al presente contrato, y a todas las relaciones jurídicas
        dimanantes del mismo, será la española, por expresa aplicación de lo dispuesto
        en el artículo 1.262 del Código Civil, en relación a lo dispuesto en el Capítulo
        IV, del Título Preliminar del mismo cuerpo legal.
      </translate>
      <translate tag="p">
        15. Algunas de las páginas del portal de Afundación disponen de cookies, que son
        pequeños ficheros de datos que se generan en el ordenador del usuario o cliente
        y que permiten a nuestros sistemas recordar el portal escogido otras
        características o preferencias de navegación del usuario. Estas "cookies" no son
        invasivas, ni nocivas, ni contienen datos de carácter personal, ya que su única
        función es personalizar su navegación en la forma antes expresada.
      </translate>
      <translate tag="p">
        16. El usuario autoriza a Afundación a incorporar los datos de carácter personal
        que aporta a las bases de datos de esta Entidad y a su tratamiento, con el fin
        de realizar todas las actividades relacionadas con la compra y entrega de las
        localidades solicitadas. El tratamiento de los datos se realizará de forma
        confidencial y de conformidad con la normativa vigente que en cada momento
        regule la protección de datos de carácter personal. Afundación garantiza los
        derechos de acceso, rectificación, cancelación y oposición en relación con estos
        datos, que podrán ser ejercitados por el usuario en cualquiera de sus oficinas.
      </translate>
      <translate tag="p">
        17. Lo que se describe en estas Condiciones Generales es a título informativo.
        Afundación excluye cualquier tipo de garantía, y, por tanto, queda libre de
        total responsabilidad derivada de los puntos expresados con anterioridad, así
        como de otros aspectos que pueden no estar contemplados en el presente
        documento.
      </translate>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionsModal",
}
</script>
