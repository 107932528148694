<template>
  <section class="payment-form">
    <form id="paymentForm" ref="paymentForm" :action="paymentForm.action" method="POST">
      <input
        type="hidden"
        :name="key"
        :value="value"
        v-for="(value, key) in paymentForm.data"
        :key="key"
      />
    </form>
  </section>
</template>
<script>
export default {
  name: "PaymentForm",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentForm: {
        action: null,
        data: {},
      },
    }
  },
  methods: {
    submit(paymentForm) {
      this.paymentForm = paymentForm

      let paymentFormEl = this.$refs.paymentForm
      setTimeout(function () {
        paymentFormEl.submit()
      }, 0)
      return true
    },
  },
}
</script>
