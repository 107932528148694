


















import { Component } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class PaymentInfo extends Vue {}
