<template>
  <div class="card">
    <div class="card-content">
      <translate tag="h1">AVISO LEGAL Y POLÍTICA DE PRIVACIDAD</translate>
      <translate tag="p">
        La Fundación Galicia Obra Social ("Afundación, en lo sucesivo"), con domicilio
        social en A Coruña, Cantón Grande 8, Código Postal 15003, N.I.F. G-70270293 e
        inscrita en el Rexistro Único de Fundacións de Interese Galego, Sección da
        Consellería de Facenda, de la Xunta de Galicia con el número 2013/12, a la que
        corresponde la titularidad del portal www.ataquilla.com (en lo sucesivo, también
        denominado el "Portal"), que pone a disposición de los usuarios de Internet, con
        el fin tanto de proporcionar información sobre los servicios, productos y
        actividades ofrecidos por Afundación, como de permitir la prestación de
        determinados servicios a través del propio Portal, denominados "on-line".
      </translate>
      <translate tag="h2">
        Aceptación de las Condiciones de Utilización del Portal
      </translate>
      <translate tag="p">
        Las presentes condiciones generales de utilización del Portal, junto con las
        condiciones generales de contratación de los servicios prestados a través del
        mismo, así como cualesquiera otras condiciones que a tales efectos puedan
        establecerse, tienen por finalidad la regulación e información a los usuarios
        del Portal de los servicios que presta Afundación, y la propia regulación del
        uso del Portal.
      </translate>
      <translate tag="p">
        La navegación y/o la utilización de los servicios del Portal suponen la
        aceptación como Usuario, y sin reservas de ningún tipo, de todas las condiciones
        generales de utilización del mismo, así como de las condiciones generales de
        contratación y de cualesquiera otras condiciones que puedan establecerse en
        relación con la prestación a través del Portal de los servicios ofrecidos por
        Afundación.
      </translate>
      <translate tag="h3">POLÍTICA DE PRIVACIDAD</translate>
      <translate tag="h4">1. Responsable del Tratamiento</translate>
      <translate tag="p">
        El Responsable del Tratamiento se corresponde con el del titular del presente
        sitio web, accesible a través de www.afundacion.org, es Afundación, cuyos datos
        figuran anteriormente.
      </translate>
      <translate tag="h4">
        2. Datos tratados, finalidades y plazo de conservación
      </translate>
      <translate tag="h5">2.1 Datos tratados:</translate>
      <translate tag="p">
        A los efectos de la solicitud de información o de contacto, la suscripción en
        nuestros boletines o newsletters, la participación en promociones o concursos o
        la compra en nuestra tienda online, se deberán facilitar unos datos a través de
        la cumplimentación de los formularios dispuestos a estos efectos, en los que se
        indicarán cuáles de ellos tienen el carácter de obligatorio.
      </translate>
      <translate tag="p">
        Durante la navegación, Afundación obtiene datos que le aportan información sobre
        usabilidad y preferencias de los usuarios, según los términos y condiciones que
        se prevén expresamente en el apartado de cookies. A estos efectos, se utilizan
        algunas que permiten interactuar con redes sociales u otras plataformas externas
        directamente desde la Web. La forma de interactuar del Usuario y la información
        obtenida durante la navegación estará sometida a la configuración de privacidad
        del propio Usuario en cada red social, pudiendo incluso esas plataformas obtener
        alguna información de su navegación, aunque el usuario no haga uso de sus
        servicios.
      </translate>
      <translate tag="h5">2.2 Finalidades:</translate>
      <translate tag="p">Las finalidades serán las siguientes:</translate>
      <translate tag="p">
        1) Contacto: El correo electrónico y/o el teléfono facilitado para el contacto,
        será/n utilizado/s para una efectiva contestación a las cuestiones planteadas.
      </translate>
      <translate tag="p">
        2) Boletines o newsletters: El correo electrónico, el cual deberá ser verificado
        por el Usuario, u otros similares, como pueda ser el teléfono; será/n
        utilizado/s para la remisión de los boletines o newsletters, de acuerdo a sus
        intereses.
      </translate>
      <translate tag="p">
        3) Promociones/concursos: Los datos aportados serán utilizados para la
        realización de cuantas gestiones sean necesarias para el desarrollo del/de la
        correspondiente promoción y/o concurso.
      </translate>
      <translate tag="p">
        4) Compra online: Los datos serán necesarios para la identificación del
        comprador y para la eventual remisión de los bienes adquiridos.
      </translate>
      <translate tag="h5">2.3 Conservación.</translate>
      <translate tag="p">El plazo de conservación será el siguiente:</translate>
      <translate tag="p">
        1) Boletines o newsletters: Los datos serán tratados, desde que el Usuario
        preste su consentimiento hasta la retirada del mismo, el cual podrá otorgarse
        cuantas veces quiera. En cada comunicación, el Usuario encontrará un
        procedimiento gratuito y accesible para gestionarlo.
      </translate>
      <translate tag="p">
        2) Promociones/concursos: Los datos serán tratados durante la sustanciación de
        la correspondiente promoción y/o concurso, incluso la entrega del premio y, una
        vez finalizado, serán guardados para la eventual tramitación de reclamaciones o
        quejas, relacionadas con el desarrollo del mismo.
      </translate>
      <translate tag="p">
        3) Compra online: Los datos deberán ser conservados durante al menos 5 años, en
        cumplimiento de la normativa fiscal y/o contable, a la que está sujeta
        Afundación.
      </translate>
      <translate tag="h4">3. Ejercicio de derechos.</translate>
      <translate tag="p">
        Cualquier persona tiene derecho a obtener confirmación acerca de si se está
        procediendo al tratamiento de datos personales que le conciernan, o no. Las
        personas interesadas tienen derecho a acceder a sus datos personales, así como a
        solicitar la rectificación de los datos inexactos o, en su caso, solicitar su
        supresión cuando, entre otros motivos, los datos ya no sean necesarios para los
        fines que fueron recogidos. En determinadas circunstancias, los interesados
        podrán solicitar la limitación del tratamiento de sus datos u oponerse al
        tratamiento de los mismos, en cuyo caso únicamente los conservará Afundación
        para el ejercicio o la defensa de reclamaciones. Para todo ello, los usuarios
        podrán dirigir una comunicación por escrito a Comité LOPD: San Andrés 135-137,
        2ª. 15003 de A Coruña, o remitir un correo a la dirección lopd@afundacion.org
        incluyendo, en ambos casos, una copia del documento nacional de identidad o
        documentación acreditativa equivalente. En caso de que desea ejercer el derecho
        de portabilidad, se facilitará esos datos al nuevo Responsable.
      </translate>
      <translate tag="h4">4. Legitimación del tratamiento.</translate>
      <translate tag="p">
        La base legal para el tratamiento de los datos facilitados será (i) el
        consentimiento otorgado por el Usuario para el tratamiento de sus datos
        personales, para o uno o varios fines específicos; (ii) la satisfacción de los
        intereses legítimos de Afundación y; (iii) la ejecución de un contrato en el que
        el Usuario es parte o para la aplicación de medidas precontractuales. El
        consentimiento será siempre revocable y podrá ejercerse en cualquier momento.
      </translate>
      <translate tag="h4">5. Destinatarios y cesiones.</translate>
      <translate tag="p">
        En el ámbito de las compras a través de www.ataquilla.com de Afundación, ésta
        podrá ceder los datos de los compradores de los eventos a aquel promotor o
        promotores que los organicen, lo que en ocasiones será considerado como
        requisito imprescindible para el acceso a las instalaciones en las que se
        llevarán a cabo.
      </translate>
      <translate tag="p">
        Aparte de lo aquí indicado, no se revelará la información personal que se
        proporcione a Afundación en el presente sitio Web a terceros, salvo cuando la
        revelación sea exigida por la legislación vigente, por orden judicial, o por una
        autoridad competente, así como cuando deba comunicar los datos indispensables
        por razón lógica de la prestación del servicio o adquisición del producto, o
        bien se pida de forma expresa dando el Usuario su consentimiento. Afundación
        podrá comunicar los datos e información imprescindible del Usuario, a las
        empresas/entidades asociadas a la compra y/o prestación del servicio, si es
        necesario que dichas empresas/entidades se pongan en contacto con el Usuario.
      </translate>
      <translate tag="p">
        Afundación no realizará cesiones de datos, salvo las expresamente contempladas
        en la legislación vigente.
      </translate>
      <translate tag="h3">RESPONSABILIDAD DE AFUNDACIÓN</translate>
      <translate tag="p">
        Afundación actualizará las especificaciones operativas que resulten necesarias
        como consecuencia de la incorporación de nuevos servicios o de la modificación
        de los ya existentes. Afundación no responderá en caso de: 1. Fuerza mayor 2.
        Utilización de los Servicios por terceros no autorizados 3. Daños a los equipos
        o sistemas de los clientes o modificaciones, alteraciones o pérdidas de datos
        que se produzcan como consecuencia de la transmisión de cualquier señal a los
        equipos de los clientes a través del sitio Web u otros medios de tecnología
        telemática, de Afundación o de fallos de seguridad de los Servicios, salvo que
        estos se deban al dolo, negligencia grave o incumplimiento por parte de
        Afundación 4. Por la mala gestión cesión y/o revelación a terceros por parte del
        Usuario de su contraseña, identificador de Usuario y/o de su Firma. 5. Posibles
        daños o perjuicios que se pudieran derivar de interferencias, comisiones,
        interrupciones, virus informáticos, averías telefónicas o desconexiones en el
        funcionamiento operativo de este sistema electrónico u otro medio telemático que
        se pudiera utilizar, motivadas por causas ajenas a Afundación; así como por
        retrasos o bloqueos en el uso de este sistema electrónico u otro medio
        telemático que se pudiera utilizar en un futuro, causados por deficiencias o
        sobrecargas de líneas telefónicas o sobrecargas en el centro de proceso de datos
        de Afundación, en el sistema dela Red de Internet o en otros sistemas
        electrónicos o telemáticos, así como los daños que puedan ser causados por
        terceras personas mediante intromisiones ilegítimas fuera del control de
        Afundación.
      </translate>
      <translate tag="p">
        Afundación declina toda responsabilidad por cualquier daño o perjuicio que
        pudiera sufrir el cliente como consecuencia de errores, defectos u omisiones en
        la información facilitada por Afundación, siempre que proceda de fuentes ajenas
        a ella o de la propia fundación basada en fuentes ajenas. Afundación no se
        responsabiliza del mal uso que se pueda realizar de los contenidos de su sitio
        Web u otros medios de tecnología telemática, siendo exclusiva responsabilidad de
        la persona que accede a ellos o los utilice.
      </translate>
      <translate tag="h3">INFORMACIÓN Y CONTENIDOS</translate>
      <translate tag="p">
        Afundación no asume responsabilidad alguna por la información contenida en
        página Web de terceros a las que se pueda acceder por enlaces, links, o
        buscadores de la página Web de Afundación. La presencia de enlaces, links, en
        las páginas Web de Afundación tiene la finalidad meramente informativa y en
        ningún caso supone sugerencia, invitación o recomendación sobre los mismos.
        Afundación no modifica o transforma el contenido de los datos de carácter
        bursátil facilitados por los diferentes mercados. Afundación, ni sus proveedores
        de contenidos son responsables de las inexactitudes o errores que en sus
        informes se puedan contener, no siendo en ningún caso responsable de las
        consecuencias del acceso, visualización y uso que las mismas pudieran derivarse.
        En cualquier caso estas informaciones y análisis tendrán carácter orientativo
        para el cliente que será el único responsable de las operaciones de carácter
        transaccional que en base a las mismas pueda realizar.
      </translate>
      <translate tag="h3">UTILIZACIÓN DEL PORTAL</translate>
      <translate tag="p">
        El Usuario reconoce que los Derechos de Propiedad Industrial e Intelectual sobre
        el contenido de las páginas Web, su diseño gráfico y códigos son titularidad de
        Afundación y/o terceros proveedoras de la información y/o contenidos facilitada
        por Afundación en base a acuerdos existentes con los mismos y que, por tanto,
        queda prohibida su reproducción, distribución, comunicación pública y
        transformación, salvo para su uso personal y privado. Igualmente todos los
        nombres comerciales, marcas o signos distintivos de cualquier clase contenidos
        en las páginas Web de Afundación, están protegidos por la Ley.
      </translate>
      <translate tag="p">
        Afundación tendrá derecho a resolver inmediatamente los contratos que en su caso
        haya suscrito el cliente y a ejercer las acciones judiciales que estime
        oportunas en el caso de que el Cliente impugne o cuestione de cualquier forma la
        validez de los citados derechos de Afundación.
      </translate>
      <translate tag="p">
        Queda prohibido, salvo en los casos en que expresamente lo autorice Afundación,
        establecer enlaces, hipervínculos o links, desde portales o sitios web de
        terceros a páginas web de Afundación distintas de la página principal de su
        portal, accesible en la dirección URL https://entradas.afundacion.org y o la que
        le sustituya en el futuro, así como presentar las páginas web de Afundación o la
        información contenida en ellas bajo frames o marcos, signos distintivos, marcas
        o denominaciones sociales o comerciales de otra persona, empresa o entidad.
      </translate>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyModal",
}
</script>
