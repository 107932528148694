










import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class TimerV2 extends Vue {
  remainingTimeStr = ""
  remainingTime = 0
  timer = 0

  @(Prop({
    type: Boolean,
    default: false,
  })!)
  isMultipassOrder!: Boolean

  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  order: any

  fixedExpiration!: Date

  getLocalDate() {
    return new Date()
  }

  mounted() {
    const serverDt: Date = new Date(String(this.order.server_dt))
    const expiration: Date = new Date(String(this.order.expiration))
    const adjust: number = serverDt.valueOf() - this.getLocalDate().valueOf() // miliseconds
    const localExpiration = new Date(expiration.valueOf() - adjust)

    this.remainingTime = localExpiration.valueOf() - this.getLocalDate().valueOf()

    this.remainingTimeChanged(this.remainingTime)

    const getRemainingTime = () => {
      if (!this.remainingTime) {
        this.remainingTime = 0
      }

      this.remainingTime = localExpiration.valueOf() - this.getLocalDate().valueOf()

      if (this.remainingTime > 0) {
        const minutes = Math.floor(this.remainingTime / 1000 / 60)
        const seconds = Math.floor((this.remainingTime / 1000) % 60)
        const minutesStr = minutes.toString().padStart(2, "0")
        const secondsStr = seconds.toString().padStart(2, "0")

        this.remainingTimeStr = `${minutesStr}:${secondsStr}`
      } else {
        this.remainingTimeStr = "00:00"
      }
    }

    getRemainingTime() // Force before 1 second delay
    this.timer = setInterval(getRemainingTime, 1000)
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }

  @Watch("remainingTime")
  remainingTimeChanged(value: number) {
    if (value <= 0) {
      if (this.isMultipassOrder) {
        this.$router.push({
          name: "multipass-order-expired",
          query: this.$defaultQuery(),
        })
      } else {
        this.$store.dispatch("order/resetEvent")
        this.$router.push({ name: "order-expired", query: this.$defaultQuery() })
      }
    }
  }
}
